@import 'view-design/dist/styles/iview.css';
@import "vue-select/dist/vue-select.css";
@import 'vue2-datepicker/index.css';

html[dir=rtl] {
    .ivu-notice-icon{
        left: 0;
    }
    .form-check .form-check-input{
        float: right;
        margin-left: 0;
        margin-right: -2.5em;
    }
    .form-check.form-switch{
        padding: 15px 2.5em 15px 0;
    }
}

.vs__search, .vs__search:focus{
    height: 39px;
}
.mx-datepicker{
    width: 100%;
}
.mx-input{
    height: 47px;
}
.form .form-group .form-control{
    border: 1px solid #dbdbdb;
}

.spanner-loader{
    min-height: 230px;
    border-radius: 30px;
}
.btn.btn-filter{
    border-radius: 4px !important;
    height: calc(100% - 10px) !important;
    margin-top: 1px !important;
}

.vue-modal{
    background: #1d1d1df0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vue-content{
    width: 550px;
    background: #fff;
    border-radius: 13px;
    max-height: 98vh;
}
.vue-modal-body{
    padding: 50px 50px 20px;
}
.vue-modal-footer{
    padding: 20px 50px;
    border-top: 1px solid #ccc;

}
.btn-light.toggle{
    border: 1px solid #bfbfbf;
    padding: 12px 0;
    color: #595959;
}
.modal-open {
    height: 98vh;
    overflow-y: hidden;
}
.form-check{
    padding: 15px 0 15px 2.5em;
}
.vs__dropdown-menu{
    z-index: 9;
}
.ivu-notice{
    top: auto !important;
    right: auto !important;
    bottom: 35px;
    left: 10px;
    z-index: 2001 !important;
}
.ivu-notice-notice{
    background: #3e3e3e;
}
.ivu-notice-desc,
.ivu-notice-title{
    color: #fff;
}
    
.vs__selected-options
{
    display: flex;
    flex-basis: 100%;
    flex-grow: 1;
    flex-wrap: nowrap;
    padding: 0 2px;
    position: relative;
    white-space: nowrap;
    max-width: 85%;
    overflow: hidden;
}

@media (max-width: 990px) {

    .vue-content{
        overflow-y: auto;
    }

}